import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import NET from 'vanta/src/vanta.net';
import { ReactComponent as LogoSVG } from '../../assets/img/LogoBranco.svg';
import Button from "../../components/Button";
import { Input } from "../../components/Input/Input";
import { LoginResponse } from "../../models/response/LoginResponse";
import { api } from "../../service/api";
import * as L from "./Login.styles";

export default function Login() {
  const navigate = useNavigate();

  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    NET({
      el: "#backgroundAnimated",
      mouseControls: false,
      touchControls: false,
      gyroControls: false,
      minHeight: 200.00,
      minWidth: 200.00,
      color: '#68cef5',
      scale: 1.00,
      backgroundColor: '#1d262f',
      scaleMobile: 1.00,
      speed: 10.00,
      points: 15.00,
      maxDistance: 14.00,
      spacing: 11.00
    })

    localStorage.clear()
  }, [])

  const handleSubmit = async (event: any) => {
    setIsLoading(true);
    event.preventDefault();

    if (!login || !password) {
      toast.error("Nome de usuário ou Senha estão inválidos!");
      setIsLoading(false);

      return
    }

    try {
      const loginResponse = await api.post<LoginResponse>('/login/', {
        username: login,
        password: password
      })

      localStorage.setItem('accessToken', loginResponse.data.tokens.access)
      localStorage.setItem('refreshToken', loginResponse.data.tokens.refresh)

      navigate('/dashboard')
    } catch (e) {
      toast.error("Nome de usuário ou Senha estão inválidos!");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <L.Wrapper id='backgroundAnimated'>
      <form onSubmit={handleSubmit}>
        <L.Form>
          <L.LogoImageContainer>
            <LogoSVG />
            {/* <img src={Logo} width={250} style={{objectFit: 'cover'}} alt={"Logo da MustHost"} /> */}
          </L.LogoImageContainer>
          <Input
            label={"Login"}
            name="username"
            onChange={(e) => setLogin(e.currentTarget.value)}
            placeholder={"Insira seu login"}
            type={"login"}
          />
          <div style={{ marginTop: '8px' }}>
            <Input
              label={"Senha"}
              name="password"
              placeholder={"Insira sua senha"}
              onChange={(e) => setPassword(e.currentTarget.value)}
              type={"password"}
            />
          </div>
          <L.ButtonGroup style={{ height: '45px' }}>
            <Button variant="primary" children={"Entrar"} isLoading={isLoading} />
          </L.ButtonGroup>
          {/* <L.ForgetPassLink>
            <a href="forgotPassword">Esqueci a senha</a>
          </L.ForgetPassLink>
          <L.TextArea>
            Ainda não é cadastrado? <a href="register">Cadastre-se</a>
          </L.TextArea> */}
        </L.Form>
      </form>
      <ToastContainer />
    </L.Wrapper>
  );
}
