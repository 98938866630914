import styled from "styled-components";

export const FormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 14px
`;

export const LineContent = styled.div`
    display: flex;
    gap: 20px;
`

