import { useEffect, useMemo, useRef, useState } from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { FiEye, FiTrash2 } from 'react-icons/fi';
import { GoSync } from "react-icons/go";
import { IoMdAddCircleOutline } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/Button";
import CardBox from "../../../components/CardBox";
import { CustomModal } from "../../../components/CustomModal";
import CustomTable from "../../../components/CustomTable";
import Filter from "../../../components/Filter";
import MachineForm from "../../../components/MachineForm";
import { StatusIndicator } from "../../../components/StatusIndicator";
import StatusMessage from "../../../components/StatusMessage";
import { api } from "../../../service/api";
import { Container, DeleteButton, Text, Timer, Value, ViewButton } from "./styel";
import { toast } from "react-toastify";

export const ListMachines = ({
    hostId
}: {hostId?: string}) => {
    const navigation = useNavigate();

    const mockedData = [
        {
            id: 1,
            status: 'ONLINE',
            name: 'Server Alpha',
            ip: '192.168.0.1',
            created_at: '2023-04-01',
        },
        {
            id: 2,
            status: 'SHUTDOWN',
            name: 'Server Beta',
            ip: '192.168.0.2',
            created_at: '2023-04-02',
        },
        {
            id: 3,
            status: 'ONLINE',
            name: 'Server Gamma',
            ip: '192.168.0.3',
            created_at: '2023-04-03',
        },
        {
            id: 4,
            status: 'SHUTDOWN',
            name: 'Server Delta',
            ip: '192.168.0.4',
            created_at: '2023-04-04',
        },
    ];

    const [servers, setServers] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [loading, setLoading] = useState(true);
    const [syncLoading, setSyncLoading] = useState(false);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [machineData, setMachineData] = useState<any>({});
    const [formLoading, setFormLoading] = useState(false);
    const [creatingMachine, setCreatingMachine] = useState(false);
    const machineDataRef = useRef({} as any)

    const fetchServers = () => {
        setLoading(true);
        api.get(`/servers/${hostId ? `?host=${hostId}` : ''}`).then(response => {
            setServers(response.data);
        }).catch(error => {
            console.error(error);
        }).finally(() => {
            setLoading(false);
        });
    }

    const deleteMachile = (data: any) => {
        api.delete(`/servers/${data.id}/`).then(() => {
            toast.success(`Máquina ${data.alias} apagada com sucesso!`)
            fetchServers();
        }).catch((error) => {
            toast.error("Aconteceu um erro ao apagar a máquina. Contate o Administrador.")
            console.log('Erro ao apagar máquinas', error)
        })
    }

    const columns = useMemo(
        () => [
            {
                Header: 'Status',
                accessor: 'status',
                Cell: ({ value }: any) => <StatusIndicator status={value} />
            },
            {
                Header: 'Nome',
                accessor: 'alias',
            },
            {
                Header: 'IP LAN',
                accessor: 'ip',
            },
            {
                Header: 'IP Público',
                accessor: 'public_ip',
            },
            {
                Header: 'Criado Em',
                accessor: 'created_at',
                Cell: ({ value }: any) => <span>{value}</span>
            },
            {
                Header: 'Ações',
                Cell: ({ row }: any) => (
                    <div style={{ textAlign: 'center' }}>
                        <ViewButton onClick={() => {
                            localStorage.setItem('server_detail', row.original.id.toString());
                            navigation('/machines/detail');
                        }} title="Visualizar">
                            <FiEye />
                        </ViewButton>
                        {/* <EditButton onClick={() => console.log(`Editar: ${row.original.id}`)} title="Editar">
                            <FiEdit />
                        </EditButton> */}
                        <DeleteButton onClick={() => deleteMachile(row.original)} title="Excluir">
                            <FiTrash2 />
                        </DeleteButton>
                    </div>
                )
            },
        ],
        []
    );

    useEffect(() => {
        fetchServers();
    }, []);


    const filteredData = useMemo(() => {
        console.log(servers.filter((item) => {
            return Object.values(item).some((value) => {
                return String(value).toLowerCase().includes(searchValue.toLowerCase());
            });
        }))
        return servers.filter((item) => {
            return Object.values(item).some((value) => {
                return String(value).toLowerCase().includes(searchValue.toLowerCase());
            });
        });
    }, [servers, searchValue]);


    const handleSync = () => {
        setSyncLoading(true);

        api.get('/sync/').then(response => {
            fetchServers();
        }).catch(error => {
            console.error(error);
        }).finally(() => {
            setSyncLoading(false);
        });
    }

    const handleCreate = () => {
        setIsOpenModal(true);
    }

    const handleSubmit = () => {
        setIsOpenModal(false);
        setCreatingMachine(true);
        let data = {
            name: machineData.name,
            ip: machineData.ip,
            based: machineData.based,
            password: machineData.password
        }
        api.post('/vcenter/create-server/', data).then(response => {
            console.log(response.data);
        })
        .catch(error => console.error(error));
    }

    const handleInputChange = (e: any) => {
        machineDataRef.current = { ...machineDataRef.current, [e.target.name]: e.target.value }
        setMachineData({ ...machineDataRef.current, [e.target.name]: e.target.value });
    }

    const secondsToMinutesFormat = (seconds: number) => {
        let minutes = Math.floor(seconds / 60);
        let remainingSeconds = seconds % 60;

        let formattedMinutes = minutes.toString().padStart(2, '0');
        let formattedSeconds = remainingSeconds.toString().padStart(2, '0');

        return `${formattedMinutes}:${formattedSeconds}`;
    }

    const interativeRenderTime = ({ remainingTime }: any) => {
        if (remainingTime === 0) {
            return <Timer>Concluindo...</Timer>;
        }

        return (
            <Timer>
                <Text>Restam</Text>
                <Value>{secondsToMinutesFormat(remainingTime)}</Value>
                <Text>segundos</Text>
            </Timer>
        );
    }

    return (
        <>
            <CustomModal
                title="Aguarde a criação da VM e não feche a janela!"
                isOpen={creatingMachine}
                onClose={() => { }}
                children={
                    <Container>
                        <CountdownCircleTimer
                            isPlaying
                            duration={900}
                            colors={["#004777", "#F7B801", "#58ca0c", "#58ca0c"]}
                            colorsTime={[300, 180, 90, 0]}
                            onComplete={() => {
                                setCreatingMachine(false);
                                handleSync();
                                return { shouldRepeat: false, delay: 1 }
                            }}
                        >
                            {interativeRenderTime}
                        </CountdownCircleTimer>
                        <StatusMessage duration={900} />
                    </Container>
                }
            />
            <CustomModal
                title="Criar nova VM"
                isOpen={isOpenModal}
                onClose={() => { setIsOpenModal(false) }}
                children={<MachineForm
                    handleSubmit={handleSubmit}
                    userData={machineData}
                    handleInputChange={handleInputChange}
                    isLoading={formLoading}
                />}
            />
            <Filter setValue={setSearchValue} value={searchValue} placeholder="Pesquise por IP, Status, Nome, Data de Criação e etc.." />
            <CardBox
                title="Lista de Máquinas"
                buttonChildren={<>
                    <Button variant="primary" icon={<GoSync />} isLoading={syncLoading} onClick={handleSync}>Sincronizar</Button>
                    <Button variant="primary" icon={<IoMdAddCircleOutline />} onClick={handleCreate}>Criar máquina</Button>
                </>}
                content={
                    <CustomTable maxHeight="calc(100vh - 300px)"
                        isLoading={loading}
                        columns={columns}
                        data={filteredData}
                        columnMinWidths={{}} />
                }
            />
        </>
    );
};