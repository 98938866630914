import styled from 'styled-components';

export const ReviewWrapper = styled.div`       
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const Title = styled.h2`
    font-size: 16px;
    color: #333;
    text-align: center;
`


export const ReviewContainer = styled.div`
  background: #f7f7f7;
  border-radius: 8px;
  padding: 20px;
  width: 100%;
`;

export const ReviewItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 16px;

  svg {
    margin-right: 8px;
    color: #007bff;
  }
`;

export const Label = styled.span`
  font-weight: 600;
  color: #333;
`;

export const Data = styled.span`
  margin-left: 4px;
  color: #666;
`;

export const GroupName = styled.span`
  font-size: 0.8em;
  margin-left: 4px;
  color: #007bff;
`;