import styled, { css } from "styled-components";

export const StepperContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    gap: 100px;
`;

export const Step = styled.div<{ isActive: boolean; isCompleted: boolean; isLast: boolean, barWidth: number }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative; 

    svg {
        font-size: 36px;
        transition: color 0.3s ease;
        z-index: 2;
        background-color: ${({ isCompleted }) => (isCompleted ? '#50c878' : '#007bff')};        
        color: ${({ isCompleted }) => (isCompleted ? '#fff' : '#fff')};
        padding: 8px;
        width: 36px;
        height: 36px;
        border-radius: 100%;
    }

    ${({ isLast, isCompleted, barWidth }) =>
        !isLast &&
        css`
            &::after {
                content:  '';
                position: absolute;
                z-index: 1;
                top: 33%;
                transform: translateY(-50%);
                right: -${barWidth}px;
                width: ${barWidth * 1.2}px;
                height: 2px;
                background: ${isCompleted ? '#50c878' : '#E0E0E0'};
                transition: background-color 0.3s ease;
            }
        `}

    ${({ isActive }) =>
        isActive &&
        css`
            svg {
                color: #fff;
                background-color: #50c878;
            }
        `}
`;

export const StepTitle = styled.div`
    margin-top: 8px;
`;