import styled, { keyframes, css } from "styled-components";

const generateRandomKeyframes = (color: string) => {
    const points = Array.from({ length: 5 }, () =>
        Math.floor(Math.random() * 100)
    );
    points.sort((a, b) => a - b);

    return keyframes`
      0% {
        background-color: white;
      }
      ${points[0]}% {
        background-color: ${color};
      }
      ${points[1]}% {
        background-color: white;
      }
      ${points[2]}% {
        background-color: ${color};
      }
      ${points[3]}% {
        background-color: white;
      }
      100% {
        background-color: ${color};
      }
    `;
};

export const ServerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2px;
`;

export const ServerShape = styled.div`
    width: 100%;
    height: 10px;
    border-radius: 3px;
    background-color: black;
    color: white;

    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

export const ServerLedConstant = styled.div<{ blinkColor: string, serverInUse?: boolean}>`
    width: 5px;
    height: 5px;

    border-radius: 50%;

    background-color: white;

    margin-right: 3px;

    background-color: ${({ blinkColor, serverInUse}) => serverInUse ? blinkColor : '#FF0000'};
`;

export const ServerLedVariable = styled.div<{
    blinkColor: string;
    duration?: string;
    serverInUse?: boolean;
}>`
    width: 5px;
    height: 5px;

    border-radius: 50%;

    background-color: white;

    margin-right: 3px;

    ${({ blinkColor, duration = "1s", serverInUse}) => serverInUse ? css`
        animation: ${generateRandomKeyframes(blinkColor)} ${duration || "1s"}
            infinite;
    ` : ''}
`;
