import { useEffect, useMemo, useState } from "react";
import { FiEdit, FiEye, FiTrash2 } from 'react-icons/fi';
import { IoMdAddCircleOutline } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/Button";
import CardBox from "../../../components/CardBox";
import { CustomModal } from "../../../components/CustomModal";
import CustomTable from "../../../components/CustomTable";
import Filter from "../../../components/Filter";
import UserForm from "../../../components/UserForm";
import { api } from "../../../service/api";
import { DeleteButton, EditButton, ViewButton } from "./styel";

export const ListUsers = () => {
    const navigation = useNavigate();
    const [servers, setServers] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [loading, setLoading] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const [form, setForm] = useState<any>({});
    const [formLoading, setFormLoading] = useState(false);
    const [userData, setUserData] = useState<any>({
        first_name: '',
        last_name: '',
        username: '',
        email: '',
        password: '',
        groups: [1]
    });

    const fetchUsers = () => {
        setLoading(true);
        api.get('/users/').then(response => {
            setServers(response.data);
        }).catch(error => {
            console.error(error);
        }).finally(() => {
            setLoading(false);
        });
    }

    const deleteUser = (id: number) => {
        api.delete(`/users/${id}/`).then(response => {
            console.log('Usuário excluído com sucesso:', response.data);
            fetchUsers();
        }).catch(error => {
            console.error('Erro ao excluir usuário:', error);
        });
    }

    const fetchUsersById = (id: number) => {
        setLoading(true);
        api.get(`/users/${id}/`).then(response => {
            setUserData(response.data);
        }).catch(error => {
            console.error(error);
        }).finally(() => {
            setLoading(false);
        });
    }

    const editUser = (id: number) => {
        fetchUsersById(id);
        setIsOpen(true);
    }

    const columns = useMemo(
        () => [
            {
                Header: 'Id',
                accessor: 'id',
            },
            {
                Header: 'Usuário',
                accessor: 'username',
            },
            {
                Header: 'Email',
                accessor: 'email',
            },
            {
                Header: 'Criado em',
                accessor: 'date_joined',
                Cell: ({ row }: any) => (
                    <span>{new Date(row.original.date_joined).toLocaleDateString()}</span>
                )
            },
            {
                Header: 'Ações',
                Cell: ({ row }: any) => (
                    <div style={{ textAlign: 'center' }}>
                        <ViewButton onClick={() => {
                            editUser(row.original.id);
                        }} title="Visualizar">
                            <FiEye />
                        </ViewButton>
                        <EditButton onClick={() => editUser(row.original.id)} title="Editar">
                            <FiEdit />
                        </EditButton>
                        <DeleteButton onClick={() => deleteUser(row.original.id)} title="Excluir">
                            <FiTrash2 />
                        </DeleteButton>
                    </div>
                )
            },
        ],
        []
    );

    useEffect(() => {
        fetchUsers();
    }, []);


    const filteredData = useMemo(() => {
        console.log(servers.filter((item) => {
            return Object.values(item).some((value) => {
                return String(value).toLowerCase().includes(searchValue.toLowerCase());
            });
        }))
        return servers.filter((item) => {
            return Object.values(item).some((value) => {
                return String(value).toLowerCase().includes(searchValue.toLowerCase());
            });
        });
    }, [servers, searchValue]);

    const handleSubmit = () => {
        setFormLoading(true);
        if (userData.id) {
            api.patch(`/users/${userData.id}/`, userData).then(response => {
                console.log('Usuário atualizado com sucesso:', response.data);
                setIsOpen(false);
                fetchUsers();
            }).catch(error => {
                console.error('Erro ao atualizar usuário:', error);
            }).finally(() => {
                setFormLoading(false);
            });
            return;
        } else {
            console.log('User data:', userData);
            api.post('/users/', userData).then(response => {
                console.log('Usuário cadastrado com sucesso:', response.data);
                setIsOpen(false);
                fetchUsers();
            }).catch(error => {
                console.error('Erro ao cadastrar usuário:', error);
            }).finally(() => {
                setFormLoading(false);
            });
        }

        setUserData(null);
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setUserData({ ...userData, [e.target.name]: e.target.name === 'groups' ? [parseInt(e.target.value)] : e.target.value });
    }


    return (
        <>
            <CustomModal
                title="Criar um novo usuário"
                isOpen={isOpen}
                onClose={() => { setIsOpen(false) }}
                children={<UserForm
                    handleSubmit={handleSubmit}
                    userData={userData}
                    handleInputChange={handleInputChange}
                    isLoading={formLoading}
                />}
            />
            <Filter setValue={setSearchValue} value={searchValue} placeholder="Pesquise por IP, Status, Nome, Data de Criação e etc.." />
            <CardBox
                title="Lista de Usuários"
                buttonChildren={<Button variant="primary" onClick={() => { setIsOpen(true) }}><IoMdAddCircleOutline />Adicionar usuário</Button>}
                content={
                    <CustomTable maxHeight="calc(100vh - 300px)"
                        isLoading={loading}
                        columns={columns}
                        data={filteredData}
                        columnMinWidths={{}} />
                }
            />
        </>
    );
};