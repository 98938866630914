import React, { ReactNode, useEffect, useRef, useState } from "react";
import { AiOutlineCheckCircle } from 'react-icons/ai';
import { Step, StepTitle, StepperContainer } from "./style";


interface StepperProps {
    steps: Array<{ title: string; icon: ReactNode }>;
    nextStep: () => void;
    prevStep: () => void;
    currentStep: number;
    seed: number;
}

export const Stepper: React.FC<StepperProps> = ({ steps, nextStep, prevStep, currentStep, seed }) => {

    const [barWidth, setBarWidth] = useState(0);
    const stepperRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (stepperRef.current) {
            const totalWidth = stepperRef.current.clientWidth;
            const stepWidth = 50;
            const numSpaces = steps.length + (seed);
            const newBarWidth = (totalWidth - stepWidth * steps.length) / numSpaces;
            setBarWidth(newBarWidth);
        }
    }, [steps.length]);

    return (
        <>
            <StepperContainer ref={stepperRef}>
                {steps.map((step, index) => (
                    <Step
                        key={index}
                        isActive={index === currentStep}
                        isCompleted={index < currentStep}
                        isLast={index === steps.length - 1}
                        barWidth={barWidth}
                    >
                        {index < currentStep ? <AiOutlineCheckCircle /> : step.icon}
                        <StepTitle>{step.title}</StepTitle>
                    </Step>
                ))}

            </StepperContainer>
        </>
    );
};
