import { ServerLedConstant, ServerLedVariable, ServerShape, ServerWrapper } from "./style";

export default function ServerAnimation({
    serverInUse
}: {serverInUse?: boolean}){
    return (
        <ServerWrapper>

            <ServerShape>
                <ServerLedConstant blinkColor="#34FE8F" serverInUse={serverInUse}/> 
                <ServerLedVariable blinkColor="#FFC136" serverInUse={serverInUse}/>
            </ServerShape>

            <ServerShape>
                <ServerLedConstant blinkColor="#34FE8F" serverInUse={serverInUse}/>
                <ServerLedVariable blinkColor="#FFC136" serverInUse={serverInUse}/>
            </ServerShape>

            <ServerShape>
                <ServerLedConstant blinkColor="#34FE8F" serverInUse={serverInUse}/>
                <ServerLedVariable blinkColor="#FFC136" serverInUse={serverInUse}/>
            </ServerShape>

        </ServerWrapper>
    )
}