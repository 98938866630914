import styled, { css, keyframes } from 'styled-components';


// Animação de pulso para o status ONLINE
const pulseAnimation = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(34, 139, 34, 0.7);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(34, 139, 34, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(34, 139, 34, 0);
  }
`;

const STATUS = {
  ligado: {
    backgrouncColor: 'lime',
    color: 'lime',
    animation: css`${pulseAnimation} 2s infinite`
  },
  desligado: {
    backgrouncColor: 'gray',
    color: 'gray',
    animation: null
  },
  desconhecido: {
    backgrouncColor: 'red',
    color: 'red',
    animation: css`${pulseAnimation} 2s infinite`
  },
};

export interface StatusProps {
  status: keyof typeof STATUS;
}

export const StatusCircle = styled.div<StatusProps>`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: ${({ status }) => STATUS[status].backgrouncColor};
  animation: ${({ status }) => STATUS[status].animation};
  transition: background-color 0.3s ease;
  margin: auto;
  position: relative;
  cursor: pointer;
  z-index: 999;

  &::after {
    content: attr(data-tooltip);
    text-transform: capitalize;
    position: absolute;
    bottom: 120%;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 4px 8px;
    border-radius: 6px;
    white-space: nowrap;
    font-size: 12px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  }

  &:hover::after {
    opacity: 1;
    visibility: visible;
  }
`;