import React, { useEffect, useRef, useState } from 'react';
import { AiOutlineLock, AiOutlineMail, AiOutlineUser } from 'react-icons/ai';
import Button from '../Button';
import { IconWrapper } from '../Filter/style';
import { Stepper } from '../Stepper';
import { UserDataReview } from '../UserReview';
import { UserFirstStep } from './FirstStep';
import { UserSecondStep } from './SecondStep';
import { ButtonContainer, ButtonWrapper, ContentContainer, StepperContainer, WizardContainer } from './style';

interface UserFormProps {
    handleSubmit: () => void;
    userData: any;
    handleInputChange: any;
    isLoading: boolean;
}

const UserForm: React.FC<UserFormProps> = ({ handleSubmit, userData, handleInputChange, isLoading }) => {
    const [activeStep, setActiveStep] = useState<number>(0);
    const [loading, setLoading] = useState(false);

    const loadingRef = useRef(false);

    useEffect(() => {
        console.log(loadingRef.current)
        setLoading(loadingRef.current)
    }, [loadingRef.current])

    const steps = [
        { title: 'Informações', icon: <AiOutlineUser /> },
        { title: 'Permissões', icon: <AiOutlineMail /> },
        { title: 'Conclusão', icon: <AiOutlineLock /> }
    ];

    const onNext = () => {
        setActiveStep(activeStep + 1);
    };

    const onPrev = () => {
        setActiveStep(activeStep - 1);
    };

    const onFinish = () => {
        handleSubmit();
    };

    console.log(loading)
    return (
        <WizardContainer>
            <StepperContainer>
                <Stepper
                    steps={steps}
                    nextStep={onNext}
                    prevStep={onPrev}
                    currentStep={activeStep}
                    seed={1}
                />
            </StepperContainer>
            <ContentContainer>
                {activeStep === 0 && (<>
                    <UserFirstStep userData={userData} handleInputChange={handleInputChange} refLoading={loadingRef}/>
                </>
                )}
                {activeStep === 1 && (
                    <UserSecondStep userData={userData} handleInputChange={handleInputChange} refLoading={loadingRef}/>
                )}
                {activeStep === 2 && (
                    <UserDataReview userData={userData} />
                )}
            </ContentContainer>
            <ButtonContainer>
                <ButtonWrapper>
                    <Button onClick={onPrev} variant='secondary'>
                        Voltar
                    </Button>
                </ButtonWrapper>
                <ButtonWrapper>
                    <Button isLoading={isLoading || loading} variant='primary' onClick={activeStep === steps.length - 1 ? onFinish : onNext}>
                        {activeStep === steps.length - 1 ? 'Concluir' : 'Próximo'}
                    </Button>
                </ButtonWrapper>
            </ButtonContainer>
        </WizardContainer>
    );
};

export default UserForm;