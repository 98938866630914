import { useEffect, useState } from "react";
import { api } from "../../../service/api";
import { Dropdown } from "../../DropDown";
import { FormWrapper, LineContent } from "../shared/style";


export const SecondStep: React.FC<any> = ({ handleInputChange, userData }) => {

    const [templates, setTemplates] = useState<any[]>([]);

    const fetchIps = () => {
        api.get('/ips/').then(response => {
            setTemplates(response.data.map((item: any) => {
                return {
                    label: item.ip,
                    value: item.ip
                }
            }));
            handleInputChange({ target: { name: 'ip', value: response.data[0].ip } });
        }).catch(error => {
            console.error('Erro ao buscar templates:', error);
        });
    }

    useEffect(() => {
        fetchIps();
    }, []);

    return (
        <FormWrapper>
            <LineContent>
                <Dropdown
                    label={"IP:"}
                    name="ip"
                    labelColor="#000"
                    value={userData?.ip}
                    options={templates}
                    onChange={handleInputChange}
                />
            </LineContent>
        </FormWrapper>
    );
}