import styled from 'styled-components';

export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  margin: 20px 0;
`;

export const SearchInput = styled.input`
  width: 100%;
  padding: 8px 8px 8px 40px;
  font-size: 16px;
  border-bottom: 2px solid #ccc;
  border-top: 2px solid #ccc;
  border-right: 2px solid #ccc;
  border-left: none;

  border-radius: 4px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  outline: none;
  transition: border 0.3s ease;
`;

export const IconWrapper = styled.div`
  align-items: center;
  display: flex;
  color: #ccc;
  padding-left: 5px;
  font-size: 20px;
  border: 2px solid #ccc;
  border-radius: 4px;
  border-right: none;
  background-color: #FFFFFF;
  height: 100%;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
`;