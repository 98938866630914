import { useState } from "react";
import { FormWrapper, LineContent } from "../shared/style";
import { Input } from "../../Input/Input";


export const ThirdStep: React.FC<any> = ({ handleInputChange, userData }) => {

    return (
        <FormWrapper>
            <LineContent>
                <Input
                    label={"Senha da Máquina"}
                    name="password"
                    value={userData?.password}
                    labelColor="#000"
                    placeholder={"Insira a senha para sua VM"}
                    type={"text"}
                    onChange={handleInputChange}
                />
            </LineContent>
        </FormWrapper>
    );
}