import { AiOutlineMail, AiOutlineUser } from 'react-icons/ai';
import { GrServer } from "react-icons/gr";
import { FiServer } from "react-icons/fi";
import { FaKey } from "react-icons/fa";
import { HiCubeTransparent } from "react-icons/hi2";

import { Data, Label, ReviewContainer, ReviewItem, ReviewWrapper, Title } from '../../UserReview/style';

interface MachineReviewProps {
    machine: {
        host: string;
        name: string;
        based: string;
        password: string;
        ip: string;
    };
}

export const MachineReview: React.FC<MachineReviewProps> = ({ machine }) => {
    return (
        <ReviewWrapper>
            <Title>Revisão de dados</Title>
            <ReviewContainer>
                <ReviewItem>
                    <GrServer />
                    <Label>Host:</Label> <Data>{machine?.host}</Data>
                </ReviewItem>
                <ReviewItem>
                    <FiServer />
                    <Label>Maquina:</Label> <Data>{machine?.name}</Data>
                </ReviewItem>
                <ReviewItem>
                    <FaKey />
                    <Label>Senha:</Label> <Data>{machine?.password}</Data>
                </ReviewItem>
                <ReviewItem>
                    <HiCubeTransparent />
                    <Label>Baseada:</Label> <Data>{machine?.based}</Data>
                </ReviewItem>
                <ReviewItem>
                    <AiOutlineMail />
                    <Label>IP:</Label> <Data>{machine?.ip}</Data>
                </ReviewItem>
            </ReviewContainer>
        </ReviewWrapper>
    );
};