
export const formatMegaBytesToGigabytes = (megaBytes: number) => {
    return (megaBytes / (1024)).toFixed(2);
}


export const formatBytesToGigabytes = (bytes: number) => {
    return (bytes / (1024 * 1024 * 1014)).toFixed(2);
}

export const formatTeraToGigaBytes = (tbytes: number) => {
    return (tbytes / (1024 * 1024)).toFixed(2);
}

export function convertToGigaOrTera(size: number, unit: 'Bytes' | 'MegaBytes'): string {
    let sizeInBytes: number;

    if (unit === 'MegaBytes') {
        sizeInBytes = size * 1024 * 1024; // Converte Megabytes para Bytes
    } else {
        sizeInBytes = size; // Se já estiver em Bytes
    }

    const sizeInGigaBytes = sizeInBytes / (1024 * 1024 * 1024);
    const sizeInTeraBytes = sizeInBytes / (1024 * 1024 * 1024 * 1024);
    
    if (sizeInTeraBytes >= 1) {
        return `${sizeInTeraBytes.toFixed(2)} TB`;
    } else if (sizeInGigaBytes >= 1) {
        return `${sizeInGigaBytes.toFixed(2)} GB`;
    } else {
        return unit === 'MegaBytes' ? `${size} MB` : `${size} Bytes`;
    }
}