import { useEffect, useState } from "react";
import { api } from "../../../service/api";
import { Dropdown } from "../../DropDown";
import { UserStepProps } from "../FirstStep";
import { FormWrapper, LineContent } from "../shared/style";


export const UserSecondStep: React.FC<UserStepProps> = ({ handleInputChange, refLoading }) => {
    const [selectedValue, setSelectedValue] = useState('');
    const [options, setOptions] = useState<any>([]);

    const handleDropdownChange = (event: React.ChangeEvent<HTMLSelectElement> | any) => {
        setSelectedValue(event.target.value);
        handleInputChange(event);
    }

    useEffect(() => {
        api.get('/groups/').then((response) => {
            refLoading.current = true;

            const options = response.data.map((group: any) => {
                return {
                    value: group.id,
                    label: group.name
                }
            });
            setOptions(options);
            setSelectedValue('1');
            handleDropdownChange({ target: { name: 'groups', value: '1' } });
        }).catch((error) => {
            console.error(error);
        }).finally(() => {
            refLoading.current = false;
        })
    }, []);

    return (
        <FormWrapper>
            <LineContent>
                <Dropdown
                    label={"Selecione o perfil do usuário"}
                    name="groups"
                    id="groups"
                    labelColor="#000"
                    options={options}
                    value={selectedValue}
                    onChange={handleDropdownChange}
                />
            </LineContent>
        </FormWrapper>
    );
}