import styled from 'styled-components';

export const Block = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 190px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  background-color: #fff;
  text-align: center;

  cursor: pointer;

  transition: box-shadow 0.3s ease;

  &:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
  }
`;

export const Status = styled.div`
  font-size: 16px;
  font-weight: bold;
`;

export const Info = styled.div`
  font-size: 14px;
  display: flex;
  flex-direction: column;
  align-items: start;
`;

export const InfoItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
`;

export const Label = styled.div`
  font-weight: bold;
`;

export const Value = styled.div`
  font-weight: normal;
`;


export const ValueBlock = styled.div`
`

export const ServerShape = styled.div`
  min-width: 50px;
`