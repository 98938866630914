import { Link } from "react-router-dom";
import styled from "styled-components";

export const COLORS = {
  white: "#ffffff",
  primary: "#585858",
  hoover: "#6c9efd",

};

export const SidebarContainer = styled.nav`
  background-color: white;
  padding: 20px;
  height: 100vh;
`;

export const SidebarList = styled.ul`
  list-style: none;
  padding: 0;
`;

export const SidebarItem = styled.li`
`;

export const SidebarLink = styled(Link)`
  color: ${COLORS.primary};
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 18px;
  padding: 10px 12px 12px 30px;
  transition: color 0.3s;
  width: 100%;

  &:hover {
    border-radius: 10px;
    background-color: ${COLORS.hoover};
    color: ${COLORS.white};
  }
`;

export const SidebarSublist = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: none; 
  &.active {
    display: block; 
  }
`;

export const SidebarSubItem = styled.li`
  padding-left: 20px;
`;

export const ToggleIcon = styled.div`
  cursor: pointer;
  margin-left: auto;
`;

export const MenuItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
`;

export const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
  width: 230px;
`;