import { useEffect, useState } from "react";
import { api } from "../../../service/api";
import { Dropdown } from "../../DropDown";
import { Input } from "../../Input/Input";
import { FormWrapper, LineContent } from "../shared/style";

export interface UserStepProps {
    handleInputChange: any;
    userData: any;
}

export const FirstStep: React.FC<UserStepProps> = ({ userData, handleInputChange }) => {

    const [templates, setTemplates] = useState<any[]>([]);
    const [hosts, setHosts] = useState<any[]>([]);

    const fetchTemplates = () => {
        api.get('/templates/').then(response => {
            console.log('Templates:', response.data);
            setTemplates(response.data.map((item: any) => {
                return {
                    label: item.description,
                    value: item.description
                }
            }));
            handleInputChange({ target: { name: 'based', value: response.data[0].description } });
        }).catch(error => {
            console.error('Erro ao buscar templates:', error);
        });
    }

    const fetchHosts = () => {
        api.get('/hosts/').then(response => {
            console.log('hosts:', response.data);
            setHosts(response.data.map((item: any) => {
                return {
                    label: item.name,
                    value: item.id
                }
            }));
            handleInputChange({ target: { name: 'host', value: response.data[0].name } });
        }).catch(error => {
            console.error('Erro ao buscar templates:', error);
        });
    }

    useEffect(() => {
        fetchTemplates();
        fetchHosts();
    }, []);

    return (
        <FormWrapper>
            <LineContent>
                <Dropdown
                    label={"Host:"}
                    name="host"
                    labelColor="#000"
                    value={userData?.based}
                    options={hosts}
                    onChange={handleInputChange}
                />
            </LineContent>
            <LineContent>

                <Dropdown
                    label={"Baseado em:"}
                    name="based"
                    labelColor="#000"
                    value={userData?.based}
                    options={templates}
                    onChange={handleInputChange}
                />
                <Input
                    label={"Nome da Máquina"}
                    name="name"
                    value={userData?.name}
                    labelColor="#000"
                    placeholder={"Insira o nome para sua VM"}
                    type={"text"}
                    onChange={handleInputChange}
                />

            </LineContent>
        </FormWrapper>
    );
}