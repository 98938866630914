import styled from "styled-components";


export const IconButton = styled.button`
  padding: 6px;
  border-radius: 4px;
  border: none;
  background: transparent;
  color: #333;
  font-size: 16px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: color 0.2s;

  &:hover {
    color: #666;
  }
`;

export const EditButton = styled(IconButton)`
  &:hover {
    color: #4CAF50;
  }
`;

export const ViewButton = styled(IconButton)`
  &:hover {
    color: #2196F3;
  }
`;

export const DeleteButton = styled(IconButton)`
  &:hover {
    color: #f44336;
  }
`;